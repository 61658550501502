/* eslint-disable */
import React from 'react';
import { Drawer, Spin, Form, DatePicker, Select, Input, Button, Space } from 'antd';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';

const EditScheduleDrawer = ({ onCloseSchedule, isScheduleDrawer, loadingSchedule, ScheduleFormData, formErrors, onChangeScheduleFields, locationHubs, bussinessModals, onSaveSchedule, form, customer }) => {

  return (
    <Drawer
      title="Edit Schedule"
      width={480}
      onClose={onCloseSchedule}
      open={isScheduleDrawer}
    >
      <Spin spinning={loadingSchedule}>
        <Form layout="vertical" form={form}>
          <Form.Item>
            <div>
              <strong>Name: </strong>
              {customer?.name
                ? customer.name
                : `${customer?.first_name ?? ''} ${customer?.last_name ?? ''}`} &nbsp;&nbsp;
              <strong>Phone: </strong>{customer?.phone}
            </div>
          </Form.Item>
          <div style={{ display: 'flex', gap: '20px' }}>
            <Form.Item
              name="date"
              label="Date"
              validateStatus={formErrors?.date ? 'error' : null}
              help={formErrors?.date}
              style={{ flexBasis: '50%' }}
            >
              <DatePicker
                name="date"
                onChange={(date, dateString) => onChangeScheduleFields(dateString, 'date')}
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              name="timeslot"
              label="Time Slots"
              validateStatus={formErrors?.timeslot ? 'error' : null}
              help={formErrors?.timeslot}
              style={{ flexBasis: '50%' }}
            >
              <Select
                placeholder={'Select a timeslot'}
                name="timeslot"
                onChange={(value) => onChangeScheduleFields(value, 'timeslot')}
                style={{ width: '100%' }}
              >
                <Select.Option value='10AM-12PM'>10AM-12PM</Select.Option>
                <Select.Option value='12PM-2PM'>12PM-2PM</Select.Option>
                <Select.Option value='2PM-4PM'>2PM-4PM</Select.Option>
                <Select.Option value='4PM-6PM'>4PM-6PM</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            name="location_hub"
            label="Location / Hub"
            validateStatus={formErrors?.location_hub ? 'error' : null}
            help={formErrors?.location_hub}
          >
            <Select
              placeholder={'Select a location'}
              name="location_hub"
              onChange={(value) => onChangeScheduleFields(value, 'location_hub')}
            >
              {locationHubs?.map((location, index) => (
                <Select.Option key={index} value={location.id}>
                  {location.location}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="vehicle_type"
            label="Vehicle Type"
            validateStatus={formErrors?.vehicle_type ? 'error' : null}
            help={formErrors?.vehicle_type}
          >
            <Select
              placeholder={'Select a vehicle type'}
              name="vehicle_type"
              onChange={(value) => onChangeScheduleFields(value, 'vehicle_type')}
            >
              <Select.Option value="electric">Electric</Select.Option>
              <Select.Option value="cng">CNG</Select.Option>
              <Select.Option value="diesel">Diesel</Select.Option>
              <Select.Option value="petrol">Petrol</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="businessmodal"
            label="Business Modal"
            validateStatus={formErrors?.businessmodal ? 'error' : null}
            help={formErrors?.businessmodal}
          >
            <Select
              placeholder={'Select a business modal'}
              name="businessmodal"
              onChange={(value) => onChangeScheduleFields(value, 'businessmodal')}
            >
              {bussinessModals?.map((modal, index) => (
                <Select.Option key={index} value={modal?.id}>
                  {modal?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="remarks"
            label="Remarks"
            validateStatus={formErrors?.remarks ? 'error' : null}
            help={formErrors?.remarks}
          >
            <Input
              name="remarks"
              onChange={(e) => onChangeScheduleFields(e.target.value, 'remarks')}
            />
          </Form.Item>

          <Space>
            <Button
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              onClick={onSaveSchedule}
              style={{ display: "flex", alignItems: "center" }}
              loading={loadingSchedule}
            >
              Update
            </Button>

            <Button
              type="danger"
              shape="round"
              icon={<CloseOutlined />}
              onClick={onCloseSchedule}
              style={{ display: "flex", alignItems: "center" }}
            >
              Cancel
            </Button>
          </Space>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default EditScheduleDrawer;
